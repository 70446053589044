/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 5/4/20
 * Time: 5:02 PM
 */
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const RESET_MESSAGE = "RESET_MESSAGE";
export const IS_ERROR = "IS_ERROR";
export const MESSAGE_OBJ = "MESSAGE_OBJ";
