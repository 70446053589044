import ApiService from './apiService';

export async function sendOTP(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.endpoint = 'user/sendOtp';
  apiObject.body = obj;
  apiObject.multipart = true;
  return await ApiService.callApi(apiObject);
}
export async function verifyOTP(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.endpoint = 'auth/signIn';
  apiObject.body = obj;
  return await ApiService.callApi(apiObject);
}
export async function loginUser(userCredentials) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.endpoint = 'auth/signIn';
  apiObject.body = userCredentials;
  return await ApiService.callApi(apiObject);
}

export async function changePassword(obj) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = 'auth/changePassword';
  apiObject.body = obj;
  return await ApiService.callApi(apiObject);
}
export async function renewToken(accessToken) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = true;
  apiObject.endpoint = 'auth/refreshToken';
  apiObject.accessToken = accessToken ?? null
  apiObject.body = {};
  apiObject.type = "RENEW_TOKEN";
  return await ApiService.callApi(apiObject);
}
