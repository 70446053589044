/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 4/20/20
 * Time: 1:56 PM
 */
import swal from "sweetalert";
import toastr from 'toastr';
import Cookies from "js-cookie";
import * as constant from "../configs/constant";
import React from "react";

import P1 from '../assets/img/pages/popular/popular1.png';
import P2 from '../assets/img/pages/popular/popular2.png';
import P3 from '../assets/img/pages/popular/popular3.png';
import P4 from '../assets/img/pages/popular/popular4.png';
import md5 from 'crypto-js/md5';

const Compress = require("compress.js").default;
export const sureTxt = "Are you sure you want to do this?";
export const properties = [
  {id:1, name:"Land for Sale"},{id:2, name:"House for Sale"},{id:3, name:"House Rentals"},{id:4, name:"Land Rentals"},{id:5, name:"Holiday & Short-Term Rental"}
]
export const Business = [
  {id:1, name:"Mobile Phones"},{id:2, name:"House for Sale"},{id:3, name:"House Rentals"},{id:4, name:"Land Rentals"},{id:5, name:"Holiday & Short-Term Rental"}
]
export const categories = [
    {id:0, name:"Taxi", image:"https://BIGBOY.LK/categories/taxi.png", count:"", subCategories: []},
    {id:1, name:"Properties", image:"https://BIGBOY.LK/categories/properties.png", count:"", subCategories: properties},
    {id:2, name:"Electronics", image:"https://BIGBOY.LK/categories/electronics.png", count:"", subCategories: []},
    {id:3, name:"Business", image:"https://BIGBOY.LK/categories/business.png", count:"", subCategories: []},
    {id:4, name:"Services", image:"https://BIGBOY.LK/categories/services.png", count:"", subCategories: []},
    {id:5, name:"Fashion", image:"https://BIGBOY.LK/categories/fashion.png", count:"", subCategories: []},
    {id:6, name:"Agriculture", image:"https://BIGBOY.LK/categories/agriculture.png", count:"", subCategories: []},
    {id:7, name:"Education", image:"https://BIGBOY.LK/categories/education.png", count:"", subCategories: []},
    {id:8, name:"Sports", image:"https://BIGBOY.LK/categories/sports.png", count:"", subCategories: []},
    {id:9, name:"Gardens", image:"https://BIGBOY.LK/categories/gardens.png", count:"", subCategories: []},
    {id:10, name:"Animals", image:"https://BIGBOY.LK/categories/animals.png", count:"", subCategories: []},
    {id:11, name:"Household", image:"https://BIGBOY.LK/categories/household.png", count:"", subCategories: []},
    {id:12, name:"Vehicles", image:"https://BIGBOY.LK/categories/vehicles.png", count:"", subCategories: []},
    {id:13, name:"Proposals", image:"https://BIGBOY.LK/categories/proposals.png", count:"", subCategories: []},
    {id:14, name:"Jobs & Others", image:"https://BIGBOY.LK/categories/job.png", count:"", subCategories: []},
  {id:15, name:"Spare Parts", image:"https://BIGBOY.LK/categories/spare-parts.png", count:"", subCategories: []},
    // {name:"Properties", image:"https://BIGBOY.LK/categories/properties.png", count:"", subCategories: []},
  ];
export const popular_categories = [
  {name:"Electronics", description:"Discover a vast selection of electronic goods on BIGBOY.LK, your go-to online classifieds destination in Sri Lanka! Whether you're in search of the latest smartphones, cutting-edge laptops, high-performance tablets, or top-of-the-line home appliances, BIGBOY.LK offers a comprehensive range of new and used electronic items to meet all your tech needs.", image:P1},
  {name:"Properties", description:"Explore premier property listings on BIGBOY.LK, the ultimate classifieds platform for real estate in Sri Lanka! Whether you're looking to buy your dream home, invest in a lucrative property, or find the perfect commercial space, BIGBOY.LK connects you with a wide range of real estate options.", image:P2},
  {name:"Vehicle", description:"Find your perfect ride on BIGBOY.LK, Sri Lanka's trusted destination for buying and selling vehicles. Whether you're in the market for a budget-friendly car, a robust SUV, a reliable motorcycle, or even a commercial vehicle, BIGBOY.LK offers an extensive selection of new and used options.", image:P3},
  {name:"Jobs & Others", description:"Kickstart your career with BIGBOY.LK, the leading job portal in Sri Lanka! Whether you're a fresh graduate or a seasoned professional, BIGBOY.LK offers a diverse array of job opportunities across multiple industries, including IT, finance, hospitality, and more.", image:P4},
];
export const notifyMessage = (msg, type, duration) => {
  let msgType = "error";

  if (type === 0) {
    msgType = "error"
  } else if (type === 1) {
    msgType = "success"
  } else if (type === 2) {
    msgType = "warning"

  }
  toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "2500",
    "hideDuration": "2500",
    "timeOut": duration === undefined ? "2500" : duration,
    "extendedTimeOut": "2500",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  };
  toastr[msgType](msg === undefined || msg === null ? "Please enter correct details" : msg, type === 0 ? 'Error' : type === 1 ? 'Success' : type === 2 ? 'Warning' : "Error")
};
export const onFileError = (error) => {
  notifyMessage(error.message);
};

export function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const warningAlert = (msg, type = 0) => {
  swal({
    title: msg,
    icon: type === 1 ? 'success' : 'error',
    closeOnClickOutside: false,
    buttons: {
      dangerMode: {
        text: "Okay",
        value: "action",
        className: "okay-btn"
      }
    }
  })
    .then((value) => {
      switch (value) {

        case "action":
          break;
        default:
      }
    })
};

export const formatCurrency = (val) => {
  val = val === undefined ? 0 : val;
  // return val.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '1,');
  return val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const checkAuthHandler = async () => {
  let isAuth = await Cookies.get(constant.ACCESS_TOKEN);
  return !!isAuth;
};
export const compressImage = async (files,returnType) => {
  const compress = new Compress();

  return await compress.compress(files, {
    size: 4,
    quality: 0.4,
    maxWidth: 1600,
    maxHeight: 1600,
    resize: true
  })
    .then(async results => {
      // console.log(results)
      const img1 = results[0];
       // img1.prefix + img1.data;
      return dataURLtoFile(img1.prefix + img1.data)
      // if(returnType==='Base64') return (img1.prefix + img1.data)
      // else return await Compress.convertBase64ToFile(img1.data, img1.ext)
    })
};
export const dataURLtoFile = (dataurl) => {
  if (dataurl !== null && dataurl !== '') {
    let filename = 'file.' + dataurl.split(';base64')[0].split('data:')[1].split('/')[1];
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

}
export const getTableHeader = (status) => {
  if(status === "DASHBOARD_USERS") {
    return <tr>
      <th>Member Name</th>
      <th>Email</th>
      <th>Phone Number</th>
      <th>Date of Registration</th>
    </tr>;
  }else if(status === "ALLOW_ZOOM") {
    return <tr>
      {/*<th>Package Name</th>*/}
      <th>Online Schedule Name</th>
      <th>Action</th>
    </tr>;
  }else if(status === "ALLOW_WORKOUT") {
    return <tr>
      {/*<th>Package Name</th>*/}
      <th>Workout Name</th>
      <th>Action</th>
    </tr>;
  }else if(status === "ALLOW_RECIPE") {
    return <tr>
      {/*<th>Package Name</th>*/}
      <th>Recipe Name</th>
      <th>Action</th>
    </tr>;
  }else if(status === "SHOP") {
    return <tr>
      <th>Image</th>
      <th>Shop Name</th>
      <th>Item Name</th>
      <th>Price</th>
      <th>Status</th>
      <th>Vendor</th>
      <th>Details</th>
      <th>Action</th>
    </tr>;
  }else if(status === "WORKOUT_VIDEO"){
    return <tr>
      <th>Image</th>
      <th>Video Name</th>
      <th>Workout</th>
      <th>Video URL</th>
      <th>Video Duration</th>
      <th>Recommend</th>
      <th>Watched Count</th>
      <th>Favourite Count</th>
      <th>Status</th>
      <th>Update</th>
      <th>Action</th>
    </tr>;
  }else if(status === "RECIPES") {
    return <tr>
      <th>Image</th>
      <th>Name</th>
      {/*<th>Description</th>*/}
      <th>Meal Type</th>
      <th>Preparing Time</th>
      <th>Calories</th>
      <th>Rating</th>
      <th>Vegetarian</th>
      <th>Status</th>
      <th>Update</th>
      {/*<th>Active/ Inactive</th>*/}
    </tr>;
  }else if(status === "WORKOUTS") {
    return <tr>
      <th>Image</th>
      <th>Name</th>
      <th>Description</th>
      <th>Rating</th>
      <th>Status</th>
      <th>Linked Videos</th>
      <th>Update</th>
      <th>Action</th>
    </tr>;
  }else if(status === "PACKAGES") {
    return <tr>
      <th>Image</th>
      <th>Name</th>
      <th>Duration</th>
      <th>Type</th>
      <th>Size</th>
      <th>Price</th>
      <th>Status</th>
      <th>Subscribers</th>
      <th>Update</th>
      {/*<th>Action</th>*/}
    </tr>;
  }else if(status === "ADMIN_USER") {
    return <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Gender</th>
      {/*<th>Weight</th>*/}
      {/*<th>Height</th>*/}
      <th>DOB</th>
      <th>Status</th>
      <th>Update</th>
      {/*<th className={"align-center_"}>Action</th>*/}
    </tr>;
  }else if(status === "V") {
    return <tr>
      <th>Name</th>
      <th>Email</th>
      <th>City</th>
      <th>Mobile</th>
      <th>User Storage</th>
      <th>Status</th>
      <th className={"align-center m-auto"}>Subscribed Packages</th>
      <th className={"align-center m-auto"}>Shop Items</th>
      {/*<th className={"align-center_"}>Action</th>*/}
    </tr>;
  }else if(status === "N") {
    return <tr>
      <th>Name</th>
      <th>Email</th>
      <th>City</th>
      <th>Mobile</th>
      <th>User Storage</th>
      <th>Status</th>
      <th className={"align-center m-auto"}>Subscribed Packages</th>
      {/*<th className={"align-center m-auto"}>Shop Items</th>*/}
      {/*<th className={"align-center_"}>Action</th>*/}
    </tr>;
  }

};
export const getBase64FromUrl = async (url) => {
  console.log("read...",url)
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function() {
      const base64data = reader.result;
      resolve(base64data);
    }
  });
}
// export const genaratePaymentHash = async () => {
//   let pgInstanceId = "";
//   let merchantId = "";
//   let currencyCode = "";
//   let amount = "";
//   let merchantReferenceNo = "";
//   let hashKey = "DE754E60A98AC04A"
//   let perform = "";
//
//   let messageHash = `${pgInstanceId}|${merchantId}|${perform}|${currencyCode}|${amount}|${merchantReferenceNo}|${hashKey}|`;
//
//   // let merchantSecret  =  PAYHERE_MERCHANT_SECRET;
//   // let merchantId      =  PAYHERE_MERCHANT_ID;
//   // let orderId         = `INV-${uuidv4()}`;
//   // let hashedSecret    = md5(merchantSecret).toString().toUpperCase();
//   // let amountFormated  = parseFloat( amount ).toLocaleString( 'en-us', { minimumFractionDigits : 2 } ).replaceAll(',', '');
//   // let currency        = 'LKR';
//   let hash            = md5(merchantId + orderId + amountFormated + currency + hashedSecret).toString().toUpperCase();
//   return {hash: hash, orderId: orderId, amount: amount, name: name};
// }
