export const ACCESS_TOKEN = "bigboy.user.access";
export const ADMIN_ID = "bigboy.user.id";
export const USER_NAME = "bigboy.user.name";
export const USER_MOBILE = "bigboy.user.mobile";
export const USER_LANGUAGE = "bigboy.user.language";

export const BASE_ROUTE_PATH = "";

export const ROUTE_DASHBOARD = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_REGISTER = "/register";
export const ROUTE_ADS = "/advertisements";
export const ROUTE_AD_PREVIEW = "/ad-preview";

export const ROUTE_PUBLIC_PAYMENT = "/f2f19dbe4bd785985e8c5470c3624521"
export const ROUTE_PUBLIC_PAYMENT_RETURN = "/payment"

export const ROUTE_PROFILE = "/settings/profile";
export const ROUTE_POST_AD = "/post-ad";
export const ROUTE_POST_PUBLISH = "/post-publish";
export const ROUTE_TAXI_ADS = "/ads/taxi";

export const ROUTE_CHAT = "/support";
export const ROUTE_OFFERS = "/offers";
export const ROUTE_FOOTER = "/footer-page";
export const ROUTE_FOOTER_POLICIES = "/footer/policies";
export const ROUTE_FOOTER_TNC = "/footer/term-and-condition";
export const ROUTE_FOOTER_REFUND_POLICY = "/footer/refund-policy";

export const JOBS_CONST = "jobs";
export const DEALS_CONST = "deals";
export const NEWS_CONST = "news";

export const HIDE_STS = "HIDDEN";
export const SHOW_STS = "SHOW";
export const DELETED_STS = "DELETED";


export const SITE_LINK = window.location.origin;

export const all_highlighted = {
    // name:"BMW i8 2018 for sale",
    // property1:"www.cardeals.com",
    // price:"2,950,000.00",
  name:"Labrador Puppies’ for sale",
  property1:"www.petcare.com",
  price:35000,
    isVerifiedMember: false, isVerifiedSeller: true, isPromotedAd: true, time: "Just Now",
    icon:"https://BIGBOY.LK/ads/all-ads.png"
  }

// category slider
export const category_slicks = {
  className: "center",
  centerMode: true,
  infinite: true,
  autoPlay: true,
  centerPadding: "10px",
  slidesToShow: 7,
  slidesToScroll: 4,
  speed: 500,
  dots: false,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}
