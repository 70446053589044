import * as constant from "./constant"

const navigationConfig = [
  {
    id: "1",
    title: "Home",
    type: "item",
    icon: null,
    permissions: ["admin","none"],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_DASHBOARD
  },
  {
    id: "1",
    title: "All Ads",
    type: "item",
    icon: null,
    permissions: ["admin","none"],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_ADS
  },
  {
    id: "7",
    title: "Support",
    type: "item",
    icon: null,
    permissions: ["admin","none"],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_CHAT
  },
  {
    id: "8",
    title: "Offers",
    type: "item",
    icon: null,
    permissions: ["admin","none"],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_OFFERS
  },
  {
    id: "6",
    title: "Profile",
    type: "item",
    icon: null,

    permissions: ["admin"],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_PROFILE
  },
  {
    id: "2",
    title: "Login",
    type: "item",
    icon: null,

    permissions: ["none"],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_LOGIN
  },
  // {
  //   id: "3",
  //   title: "Register",
  //   type: "item",
  //   icon: null,
  //
  //   permissions: ["admin"],
  //   navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_REGISTER
  // },
];

export default navigationConfig
