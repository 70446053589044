import React from "react"
import {Navbar} from "reactstrap"
import classnames from "classnames"
import AdsIcon from '../../../assets/img/icons/all-ads.png';
import ChatIcon from '../../../assets/img/icons/chat.png';
import OfferIcon from '../../../assets/img/icons/offers.png';
import RegIcon from '../../../assets/img/icons/register-icon.png';
import LoginIcon from '../../../assets/img/icons/login-icon.png';
import LoggedUserIcon from '../../../assets/img/icons/logged-user.png';
import NavbarUser from "./NavbarUser"
import Logo from '../../../assets/img/logo/logo-white.png';
import './Navbar.scss';
import NavbarBookmarks from "./NavbarBookmarks";
import * as constant from "../../../configs/constant";
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";
import * as config from "../../../configs/constant";
import {ROUTE_OFFERS} from "../../../configs/constant";
// import connect from "react-redux/es/connect/connect";
let auth = null;
const ThemeNavbar = props => {
  // const [auth, setAuth] = useState(null);
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  let token = Cookies.get(config.ACCESS_TOKEN);
  if(token !== undefined ){
    // token = JSON.parse(token);
    // this.props.setAuth(token);
    // setAuth(token)
    auth = token;
  }else{
    // setAuth(null)
    auth = null;
    // this.props.history.push(`${config.BASE_ROUTE_PATH}/login`)
  }
  let userName = localStorage.getItem(constant.USER_NAME)
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          {/*navbar-container content*/}
          <div className="container_ pr-2 pl-2">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper mob-burger d-lg-none">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              <div className="bookmark-wrapper nav-logo-wrapper">
                <img className={''} src={Logo} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH+constant.ROUTE_DASHBOARD)}/>
              </div>
              <div className="bookmark-wrapper nav-header-labels">
                {/*<Button.Ripple>*/}
                {/*  <img className={'ml-0'} src={AdsIcon} />*/}
                {/*  <p>All Ads</p>*/}
                {/*</Button.Ripple>*/}
                <div className={'nav-actions'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH+constant.ROUTE_ADS)}>
                  <img className={'ml-0'} src={AdsIcon} />
                  <p>All Ads</p>
                </div>
                <div className={'nav-actions'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH+constant.ROUTE_CHAT)}>
                  <img className={''} src={ChatIcon} />
                  <p>Support</p>
                </div>
                <div className={'nav-actions'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH+constant.ROUTE_OFFERS)}>
                  <img className={''} src={OfferIcon} />
                  <p>Offers</p>
                </div>
                {auth ? <div className={'nav-actions'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH+constant.ROUTE_PROFILE)}>
                  <img className={''} src={LoggedUserIcon} />
                  <p>{userName ? userName : "Profile"}</p>
                </div>:null}
                {auth === null ? <div className={'nav-actions'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH+constant.ROUTE_LOGIN)}>
                  <img className={''} src={LoginIcon} />
                  <p>Login</p>
                </div>:null}
                {/*{auth === null ? <div className={'nav-actions'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH+constant.ROUTE_REGISTER)}>*/}
                {/*  <img className={''} src={RegIcon} />*/}
                {/*  <p>Signup</p>*/}
                {/*</div>:null}*/}
              </div>

              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                // userName={props.profile ? props.profile.profile.companyName ? props.profile.profile.companyName : "Loading .." : "Loading .."}
                // userImg={props.profile ? props.profile.profile.profileImage ? props.profile.profile.profileImage : CorporateImage : CorporateImage}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}


export default withRouter(ThemeNavbar);
