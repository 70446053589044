import React from "react"
import ScrollToTop from "react-scroll-up"
import {Button, Col, Row} from "reactstrap"
import { ArrowUp } from "react-feather"
import classnames from "classnames"
import Logo from '../../../assets/img/logo/logo-white.png'
import * as constant from "../../../configs/constant";
import {withRouter} from "react-router-dom";
import {ROUTE_FOOTER_REFUND_POLICY} from "../../../configs/constant";
const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"];
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden"
      })}
    >
      <div className={'container'}>
        <Row className={'ft-about-wrapper-top row-margin'}>
          <Col md={12}>
            <p className={'title'}>BIGBOY.LK</p>
            <p className={'about-txt'}>Welcome to BIGBOY.LK, Sri Lanka's dynamic online marketplace where you can buy, sell, and explore a vast selection of products and taxi services.</p>
          </Col>
          <Col xs={6} className={'ft-about-mid-wrapper f-s'}>
            <div className={'one-s'}>
              <img src={require("../../../assets/img/icons/tel.png")} />
              <p>077 2419992 </p>
            </div>
          </Col>
          <Col xs={6} className={'ft-about-mid-wrapper'}>
            <div className={'second-s'}>
              <img src={require("../../../assets/img/icons/email.png")} />
              <p>info@bigboy.lk</p>
            </div>
          </Col>
        </Row>
      </div>
      <hr color={'white'}/>
      <div className={'container mb-1'}>
        <Row>
          <Col md={3} className={'ft-about-wrapper p-0 logo-with-social'}>
            <img src={Logo} />
            <div className={'social-media-icons'}>
              <a href={'https://facebook.com'} target={"_blank"}><img src={require("../../../assets/img/icons/fb-icon.png")} /></a>
              <a href={'https://wa.me/+94772419992'} target={"_blank"}><img src={require("../../../assets/img/icons/whatsapp-icon.png")} /></a>
              {/*<a href={'https://www.linkedin.com/'} target={"_blank"}><img src={require("../../../assets/img/icons/linkedin-icon.png")} /></a>*/}
              {/*<a href={'https://www.instagram.com'} target={"_blank"}><img src={require("../../../assets/img/icons/insta-icon.png")} /></a>*/}
            </div>
          </Col>
          {/*<Col md={3} className={'ft-sub-wrapper'}>*/}
          {/*  <p>More Features</p>*/}
          {/*  <p>Sell with 2X</p>*/}
          {/*  <p>Membership</p>*/}
          {/*  <p>Ads</p>*/}
          {/*  <p>Promotions</p>*/}
          {/*  <p>Solutions</p>*/}
          {/*</Col>*/}
          <Col md={4} className={'ft-sub-wrapper'}>
            <p ></p>
            <p className={'link-p'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH + constant.ROUTE_FOOTER)}>FAQ’s</p>
            <p className={'link-p'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH + constant.ROUTE_FOOTER)}>Contact Us</p>
            <p className={'link-p'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH + constant.ROUTE_FOOTER)}>About us</p>
          </Col>
          <Col md={4} className={'ft-sub-wrapper'}>
            <p ></p>
            <p className={'link-p'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH + constant.ROUTE_FOOTER_TNC)}>Terms & Conditions</p>
            <p className={'link-p'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH + constant.ROUTE_FOOTER_POLICIES)}>Policies</p>
            <p className={'link-p'} onClick={()=>props.history.push(constant.BASE_ROUTE_PATH + constant.ROUTE_FOOTER_REFUND_POLICY)}>Refund Policy</p>
          </Col>
        </Row>
      </div>
      <p className="mb-0_ clearfix w-100_ text-center footer-credit">
        {/* © {new Date().getFullYear()} */}
        <span className="float-md-left d-block d-md-inline-block w-100">
          {/*ALL RIGHTS RESERVED @ {new Date().getFullYear()}*/}
          All rights reserved @ BIGBOY.LK
        {/*  | Developed by<a href={'https://www.webslk.com/'} target={"_blank"}>Web Solutions Lanka</a>*/}
        </span>
      </p>
      {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={360}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
    </footer>
  )
}

export default withRouter(Footer)
