import axios from './axiosConfig';
import apiConfig from './apiConfig';
import Cookies from "js-cookie";
import * as constant from "../configs/constant";
import {renewToken} from "./auth";
import swal from "sweetalert";

async function callApi(apiObject) {
  let body = {};
  let headers;
  let method = apiObject.method ? apiObject.method.toLowerCase() : 'get';
  if (method === 'post' || method === 'put' || method === 'patch') {
    body = apiObject.body ? apiObject.body : {};
  }

  headers = {
    'Content-Type': apiObject.urlencoded ? 'application/x-www-form-urlencoded' : apiObject.multipart ? 'multipart/form-data':'application/json',
  };
  if (apiObject.authentication) {
    headers.Authorization = `Bearer ${Cookies.get(constant.ACCESS_TOKEN)}`;
  }
  if (apiObject.accessToken) {
    headers.Authorization = `Bearer ${apiObject.accessToken}`;
  }
  if (apiObject.type === "RENEW_TOKEN") {
    headers.isRefreshToken = true;
  }
  let serverUrl = apiConfig.serverUrl;
  let basePath = apiConfig.basePath;

  if (apiObject.basePath) basePath = apiObject.basePath;

  const url = `${serverUrl}/${basePath}/${apiObject.endpoint}`;
  let result;

  await axios[method](url, method !== 'get' && method !== 'delete' ? body:{headers: headers} , {headers: headers})
    .then(async response => {
      result = {...await response.data, desc: response.data.desc ? response.data.desc: response.data.result, status: response && response.status ? response.status : 0} ;
    })
    .catch(async error => {
      if (error !== undefined) {
        // console.log("error.response.data",error.response.data)
        if (error.response === undefined) {
          result = await {
            success: false,
            status: 2,
            result: "Your connection was interrupted",
            data: null,
          }
        } else if (error.response.status === 401) {
          if (apiObject.type === "RENEW_TOKEN") {
            result = await {
              success: false, status: 2,
              result: "Your session expired! Please login again..",
              data: null,
            };
          }else if(apiObject.type !== "AUTH"){
            result = await renewTokenHandler(apiObject);
          }else{
            result = await {
              success: false, status: 0,data: null,
              result: error?.response?.data?.message ?? "Your session expired! Please login again.."
            };
          }
        } else if (error.response.status === 500) {
          result = await  {
            success: false,
            status: 2,
            result: error?.response?.data?.desc ?? "Access is denied.",
            data: null,
          };

        } else if (error.response.status === 403) {
          result = await  {
            success: false,
            status: 2,
            result: "Access is denied.",
            data: null,
          };
        } else if (error.response.status === 417) {
          result = await  {
            success: false,
            status: 2,
            result: "Oops! Something went wrong.",
            data: null,
          };
        } else if(error.response.data !== undefined){
          result = await {
            success: false,
            status: 0,
            result: error.response.data.result ? error.response.data.result : 'Sorry, something went wrong',
            data: null,
          }
        } else {
          result = await  {
            success: false,
            status: 2,
            result: "Sorry, something went wrong.",
            data: null,
          };
        }
      } else {
        result = await  {
          success: false,
          status: 2,
          result: "Your connection was interrupted!",
          data: null,
        };
      }
    });
  return result;
}
//refreshToken
export const renewTokenHandler = async (apiObject) => {
  let result;
  await renewToken(apiObject.accessToken).then(async response => {
    if (response.code === "200") {
      if(apiObject.accessToken) {
        apiObject.accessToken = response.result
      }else{
        Cookies.set(constant.ACCESS_TOKEN, response.result);
      }
      result = await callApi(apiObject);
    } else {
      result = await {
        success: false, status: 2, data: null,
        result: "Your session has expired! Please login again..",
      };
      if(apiObject.accessToken) {
        result = await {
          success: false, status: 2, data: null,
          result: "Your session has expired!",
        };
      }else{
        swal({
          title: "Your session has expired! Please login again..",
          closeOnClickOutside: false,
          buttons: {
            dangerMode: {text: "Okay", value: "action", className: "okay-btn"}
          }
        })
          .then((value) => {
            switch (value) {
              case "action":
                Cookies.remove(constant.ACCESS_TOKEN);
                window.location = constant.BASE_ROUTE_PATH+constant.ROUTE_LOGIN;
                break;
              default:
            }
          })
      }
    }
  });
  // renew token - end
  return result;
};
export default {callApi};
